import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import headerStyles from './header.module.scss'

const Header = () => {
    const data = useStaticQuery(graphql`
        query{
            site{
                siteMetadata{
                    title
                }
            }
        }
    `)

    return (
        <header className={headerStyles.header}>
        <nav>
            <ul className={headerStyles.navList}>
                <li><Link className={headerStyles.title} activeClassName={headerStyles.activeNavItem} to="/">{data.site.siteMetadata.title}</Link></li>
                <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link></li>
                <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/lifelog">Life log</Link></li>
                <li><a className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} href="http://ladyofcode.com">Projects</a></li>
            </ul>
        </nav>

        </header>
    )
}

export default Header